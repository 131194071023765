<template>
	<div class="filter-wrapper">
		<div class="filter-wrapper__head" @click="switchState">
			<div class="filter-wrapper__head--title">
				{{ filter.title }}
				<span class="counter">[{{ filter.selected.length }}]</span>
			</div>
			<div class="filter-wrapper__head--contoller" :class="{inactive: !isActive}">
				<icon icon="v2-chevron-down" colour="transparent" />
			</div>
		</div>
		<div class="filter-wrapper__body" :class="{closed: !isActive}">
			<template v-if="filter.type === 'Checkbox'">
				<v2-checkbox v-model="filter.selected" :options="filter.values" @input="updateFilter" />
			</template>
			<template v-if="filter.type === 'Dropdown'">
				<v2-search-dropdown v-model="filter.selected" :options="filter.values" @input="updateFilter" />
			</template>
		</div>
	</div>
</template>

<script>
	import Icon         from '@/components/ui/Icon';
	import V2Checkbox         from '@/components/v2/ui/checkbox/V2Checkbox';
	import V2SearchDropdown   from '@/components/v2/ui/V2SearchDropdown.vue';

	export default {
		components: {
			Icon,
			V2Checkbox,
			V2SearchDropdown
		},

		props: {
			filter: {
				type: Object,
				required: true
			}
		},

		data: () => {
			return {
				isActive: true
			};
		},

		methods: {
			switchState () {
				this.isActive = !this.isActive;
			},

			updateFilter () {
				this.$emit('update-filter');
			}
		}

	};
</script>

<style lang="scss" scoped>

.filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  background-color: $c-bg-white;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid $c-gray-d2;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--title {
      display: flex;
      align-items: center;
      gap: 4px;

      @include font(16px, $c-dark-navy, 500, 20px);

      .counter {
        @include font(10px, $c-gray-d3, 500, 13px);
      }
    }

    &--contoller {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s ease-in-out;
      transform: rotate(0deg);

      &.inactive {
        transform: rotate(180deg);
      }
    }

  }

  &__body {
      transition: all 0.1s ease-in-out;
      height: auto;
      max-height: 500px;

      &.closed {
        max-height: 0;
        opacity: 0;
      }
  }
}

</style>
