<template>
	<div class="checkbox-wrapper">
		<v2-checkbox-item
			v-for="checkbox in options"
			:key="checkbox.id"
			:value="checkbox.id"
			:selected="isSelected(checkbox.id)"
			@change="onChange"
		>
			{{ checkbox.label }}
		</v2-checkbox-item>
	</div>

</template>

<script>
	import V2CheckboxItem from '@/components/v2/ui/checkbox/V2CheckboxItem.vue';

	export default {
		components: {
			V2CheckboxItem
		},

		props: {
			options: {
				type: Array,
				required: true
			},
			value: {
				type: Array,
				required: true
			}
		},

		computed: {
			includeAll () {
				return this.options.map(({ id }) => id).includes(-1);
			}
		},

		methods: {
			isSelected (id) {
				return this.value.includes(id);
			},

			onChange (itemId) {
				let newValue;

				const removeSelected = () => {
					newValue = this.value.filter((id) => id !== itemId && id !== -1);
				};

				const removeAll = () => {
					newValue = [];
				};

				const addAll = () => {
					newValue = this.options.map(({ id }) => id);
				};

				const addSelected = () => {
					newValue = [...this.value, itemId];
          if (newValue.length + 1 === this.options.length) {
            newValue.push(-1);
          }
				};

        if (this.includeAll) {
           if (this.isSelected(itemId)) {
            if (itemId === -1) {
              removeAll();
            } else {
              removeSelected();
            }
          } else {
            if (itemId === -1) {
              addAll();
            } else {
              addSelected();
            }
          }
        } else {
          if (this.isSelected(itemId)) {
            removeSelected();
          } else {
            addSelected();
          }
        }

				this.$emit('input', newValue);
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
