<template>
	<div class="search-input">
		<icon class="search-input__icon" icon="v2-search" colour="transparent" />
		<input
			:value="value"
			@input="$emit('input', $event.target.value)"
			class="search-input__input"
			placeholder="Search" />
		<slot name="icon" />
	</div>
</template>

<script>
	import Icon         from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			value: {
				type: [String, Number],
				required: true
			}
		}
	};
</script>

<style lang="scss" scoped>

.search-input {
  width: 100%;
  padding: 4px 8px 4px 14px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 3px;
  background: $c-white-l;
  @include font();

  &__input {
    border: none;
    width: 100%;
    height: 22px;

    &:focus{
      outline: none;
    }
  }
}

</style>
